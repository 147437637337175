@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background: black;
}
canvas {
  width: 100% !important;
  height: 100% !important;
}
input {
  background: none;
  outline: none;
  border: 0px;
}

.bg-default {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.common-box {
  background: linear-gradient(to right, rgb(37, 48, 36), rgb(21, 21, 21));
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: initial;
  border-image: linear-gradient(200deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0)) 1 / 1 / 0 stretch;
}
.common-box2 {
  background: rgba(62, 62, 62, 0.5);
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: initial;
  border-image: linear-gradient(155deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0)) 1 / 1 / 0 stretch;
}